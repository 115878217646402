import {useParams, usePathname} from "next/navigation";
import {useRouter} from "next/router";

function usePathFunc(): () => NextJSPagesPaths {
  try {
    const router = useRouter();
    return () => ({asPath: router.asPath, pathname: router.pathname});
  } catch (e) {
    return usePathsAppDirectory;
  }
}

function usePathsAppDirectory(): NextJSPagesPaths {
  const asPath = usePathname();
  const params = useParams();

  // These should always be defined in app directory, for type narrowing
  if (!asPath || !params) throw new Error("Could not get asPath or params");

  // In params, the keys are the names of the dynamic segments in the path,
  // and the values are the values of those segments. For example, if you have
  // locations/[slug], then params will look something like {slug: "berkeley"}.
  // asPath is in the format /locations/berkeley, and we want to create
  // pathname in the format /locations/[slug].
  const pathname = Object.keys(params).reduce((acc, key) => {
    const value = params[key];
    if (typeof value !== "string") return acc;
    return acc.replace(value, `[${key}]`);
  }, asPath);

  return {asPath, pathname};
}

/**
 * The pathname and asPath as they are defined by the Pages directory router
 */
type NextJSPagesPaths = {
  /**
   * The path as it would be in the browser, such as `/locations/berkeley`.
   */
  asPath: string;
  /**
   * The path as it would be in the directory, such as `/locations/[slug]`.
   */
  pathname: string;
};

/**
 * Get the pathname and asPath corresponding to the Pages directory router
 * from next/router.
 *
 * This works even in the app directory, where next/router is not available.
 *
 * This is a temporary utility for the transition to the pages directory,
 * to support components that are used in both pages and app directories.
 *
 * Once we have completed the transition to app directory or there are no
 * remaining shared components using pathname/asPath, we should remove
 * this utility.
 *
 * Note that when NOT using this utility, "pathname" has different meanings
 * in the pages and app directories. In the pages directory, "pathname" is
 * the path as it would be in the directory, such as `/locations/[slug]`.
 * In the app directory, "pathname" is the path as it would be in the browser,
 * such as `/locations/berkeley`.
 *
 * When using this utility, "pathname" has the same meaning in both directories,
 * corresponding to the path as it would be in the directory, such as
 * `/locations/[slug]`.
 *
 * WARNING: The creation of pathname in the app directory is a bit of a hack,
 * as it is not officially supported by Next.js. Avoid using it if possible,
 * and always test that it works correctly for new pages.
 */
export default function useNextPagesPaths(): NextJSPagesPaths {
  const usePath = usePathFunc();
  return usePath();
}
