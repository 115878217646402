import React from "react";
export const V5_ROOT = "/static/img/v5";

export const useV5ImgSrc = (src: string, isSvg = false): string => {
  // @ts-expect-error TS2322: Type 'null' is not assignable to type 'string'.
  if (!src) return null;
  return `${V5_ROOT}${src[0] === "/" ? "" : "/"}${src}.${isSvg ? "svg" : "jpg"}`;
};

export type V5ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  isSvg?: boolean;
  alt: string;
};

// eslint-disable-next-line react/display-name
export const V5Image = React.memo(
  ({src, isSvg, alt, ...props}: V5ImageProps): React.ReactElement => (
    // @ts-expect-error TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    <img src={useV5ImgSrc(src, isSvg)} alt={alt || ""} {...props} />
  ),
);
