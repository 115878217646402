import {uniqBy} from "lodash";

import {
  LocationFragment,
  getRegionsFromLocationsBySpecialtyIds,
} from "../../components/v5/Region/utils/getRegionsFromLocationsBySpecialtyIds";
import hardcodedVirtualRegions from "../../constants/hardcodedVirtualRegions";
import {getUserSelectedLocation} from "../browser-storage/userSelectedLocation";
import {useFetchNearestRegionQuery} from "./__generated__/fetch-nearest-region.type";
import {useUserCoordinates} from "../../hooks/useGeolocateUser";
import {useMemo} from "react";

export type Region = {
  key: string;
  name: string;
  textValue: string;
  slug: string;
};

export const generateRegionData = (
  locations: LocationFragment[],
  specialtyIds?: string[],
  hideVirtualOnly = false,
): Region[] => {
  const regions = [
    ...getRegionsFromLocationsBySpecialtyIds(locations, specialtyIds),
    ...(hideVirtualOnly ? [] : hardcodedVirtualRegions),
  ];
  return uniqBy(
    regions.map(region => ({
      key: region.slug,
      textValue: region.name,
      name: region.name,
      slug: region.slug,
    })),
    region => region.slug,
  );
};

/**
 * Get a good "default" region for this user. Should be used to initialize pages with
 * a region selector in the absence of other useful info.
 */
export const usePreferredRegion = (practiceId: string) => {
  // The main use-case for this method is to _initialize_ the region selector.
  // This uses `getUserSelectedLocation` rather than `useUserSelectedLocation` because
  // the state updates (which would happen any time the user changes the region in the
  // dropdown) don't matter after the initial render.
  const savedRegion = getUserSelectedLocation()?.regionSlug;
  const {data: userCoordinates, isSettled} = useUserCoordinates({skip: !!savedRegion});
  const {data: nearestRegion, loading: graphqlLoading} = useFetchNearestRegionQuery({
    variables: {
      practiceId,
      latitude: userCoordinates.x,
      longitude: userCoordinates.y,
    },
    skip: !!savedRegion || !isSettled,
  });
  const regionSlug = savedRegion ?? nearestRegion?.practice?.nearestRegion?.slug ?? "sf-bay-area";
  const data = useMemo(() => ({slug: regionSlug}), [regionSlug]);
  const loading = !savedRegion && (!isSettled || graphqlLoading);
  return useMemo(() => ({data, loading}), [data, loading]);
};
