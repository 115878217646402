import {PracticeApptReason} from "@services/types";
import {ApptSlot} from "@services/monolith/availability";
import {useCallback} from "react";

import {SpecialtyId, virtualSpecialtyIds} from "../constants/specialtyIds";
import {fetchCachedSlot} from "../utils/fetchCachedSlot";
import {getStateCodeFromRegionSlug} from "../utils/stateUtils";
import {QueryState, useQueryController} from "./useQueryController";
import {useUserSelectedLocation} from "../utils/browser-storage/userSelectedLocation";

export const orderVirtualSpecialtyIds = (ids: string[]): string[] =>
  [
    ids[ids.indexOf(SpecialtyId.VIRTUAL_URGENT_CARE)],
    ids[ids.indexOf(SpecialtyId.VIRTUAL_PRIMARY_CARE)],
    ids[ids.indexOf(SpecialtyId.VIRTUAL_MENTAL_HEALTH)],
    ids[ids.indexOf(SpecialtyId.VIRTUAL_THERAPY)],
  ].compact();

export const getVirtualSpecialtyIds = (apptReason: PracticeApptReason): string[] =>
  orderVirtualSpecialtyIds(
    apptReason.specialtyIds.filter(id =>
      virtualSpecialtyIds.includes(id as (typeof virtualSpecialtyIds)[number]),
    ),
  );

/**
 * Attempts to find slots, specialty by specialty in the provided state in order of ids given
 * @param virtualIds virtual specialty ids in order to check
 * @param state only slots with doctors licensed in this state will be returned
 */
export const getSoonestVirtualSlot = (
  virtualIds: string[],
  state: string,
  reasonId?: string,
  availabilityEnabled = true,
): Promise<ApptSlot | null> =>
  availabilityEnabled
    ? virtualIds.reduce<Promise<ApptSlot | null>>(
        async (
          maybeSlot: Promise<ApptSlot | null>,
          specialtyId: string,
        ): Promise<ApptSlot | null> =>
          (await maybeSlot) || fetchCachedSlot({specialtyId, reasonId, selectedState: state}),
        Promise.resolve(null),
      )
    : Promise.resolve(null);

export const useSoonestVirtualSlotInState = (
  specialtyIds: string[],
  reasonId?: string,
): QueryState<ApptSlot | null> => {
  const {regionSlug: selectedRegion} = useUserSelectedLocation();
  const state = getStateCodeFromRegionSlug(selectedRegion);

  const fn = useCallback(
    () => getSoonestVirtualSlot(specialtyIds, state || "CA", reasonId),
    [specialtyIds, state, reasonId],
  );

  return useQueryController({
    fn,
    cacheKey: state || "CA",
    skip: !state,
    ifRejectedValue: null,
    initialValue: null,
  });
};
