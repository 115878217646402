import {Url} from "next/dist/shared/lib/router/router";
import React, {PropsWithChildren, useCallback} from "react";

import {useActiveIndexController} from "../../../../hooks/useActiveIndexController";
import Button, {ButtonSize, ButtonVariant} from "../../Button";
import {LinkProps} from "next/link";

export type TreeItemId = string | number;

export type TreeItem<T = unknown> = {
  id: TreeItemId;
  children?: TreeItem<T>[];
  data: T;
};

type Props = {
  tree: TreeItem<string>;
  defaultExpandedItems: TreeItemId[];
  selectedItemId: TreeItemId;
  onSelect: (item: TreeItem<string>) => unknown;
  getHref?: (item: TreeItem<string>) => Url | undefined;
  getAs?: (item: TreeItem<string>) => LinkProps["as"] | undefined;
  shallow?: LinkProps["shallow"];
};

const Group = ({children}: React.PropsWithChildren<unknown>) => (
  <ul role="group" data-cy="select-dropdown" className="flex flex-col justify-stretch gap-1">
    {children}
  </ul>
);

const ListItem = ({isSelected, children}: PropsWithChildren<{isSelected: boolean}>) => (
  <li
    role="treeitem"
    aria-selected={isSelected}
    className="flex flex-grow flex-shrink-0 flex-col gap-2"
  >
    {children}
  </li>
);

const Root = ({children}: PropsWithChildren<unknown>) => (
  <div
    className="pv3 ph6 mh-6 ovf-y-a ovf-x-a"
    style={{maxHeight: "22rem"}}
    aria-labelledby="react-aria-select-descriptor"
    role="tree"
  >
    {children}
  </div>
);

const ListItemButton = ({
  href,
  onClick,
  item,
  isSelected,
  isExpanded,
  level,
  shallow,
  as,
}: {
  isSelected: boolean;
  item: TreeItem<string>;
  onClick: () => void;
  isExpanded: boolean;
  href?: Url;
  level: 1 | 2;
  shallow?: LinkProps["shallow"];
  as?: LinkProps["as"];
}) => (
  <Button
    size={ButtonSize.SM}
    variant={isSelected ? ButtonVariant.GREEN : ButtonVariant.NAKED}
    href={href}
    as={as}
    shallow={shallow}
    onClick={onClick}
    className={`flex-grow ${isSelected ? "" : "hover:bg-gray-100"}`}
    data-cy={`item-${item.id}`}
  >
    <span className={`flex items-center flex-grow justify-between ${level === 2 ? "pl-6" : ""}`}>
      {item.data}
      {item.children ? (
        isExpanded ? (
          <span className="cIcon-dropdown-arrow-up" aria-hidden />
        ) : (
          <span className="cIcon-dropdown-arrow-down" aria-hidden />
        )
      ) : isSelected ? (
        <span className="cIcon-check" aria-hidden />
      ) : null}
    </span>
  </Button>
);

const Tree = ({
  onSelect,
  selectedItemId,
  defaultExpandedItems,
  tree,
  getHref,
  getAs,
  shallow,
}: Props) => {
  const expandedItemsController = useActiveIndexController<TreeItemId>(defaultExpandedItems);
  const handleSelect = useCallback(
    (item: TreeItem<string>) => {
      if (item.children) {
        expandedItemsController.toggle(item.id);
      } else {
        onSelect(item);
      }
    },
    [expandedItemsController, onSelect],
  );

  return (
    <Root>
      <Group>
        {tree.children?.map((item, i) => {
          const isSelected = item.id === selectedItemId;
          const expandedChildren = expandedItemsController.isActive(item.id) && item.children;

          const isOpen = Array.isArray(expandedChildren);
          return (
            <ListItem key={i} isSelected={isSelected}>
              <ListItemButton
                level={1}
                href={getHref?.(item)}
                as={getAs?.(item)}
                onClick={() => handleSelect(item)}
                item={item}
                isSelected={isSelected}
                isExpanded={expandedItemsController.isActive(i)}
                shallow={shallow}
              />
              <div className={isOpen ? "" : "opacity-0 invisible"}>
                <Group>
                  {isOpen &&
                    expandedChildren.map((item, i) => {
                      const isSelected = item.id === selectedItemId;
                      return (
                        <ListItem key={i} isSelected={isSelected}>
                          <ListItemButton
                            level={2}
                            href={getHref?.(item)}
                            as={getAs?.(item)}
                            onClick={() => handleSelect(item)}
                            item={item}
                            isSelected={isSelected}
                            isExpanded={expandedItemsController.isActive(i)}
                            shallow={shallow}
                          />
                        </ListItem>
                      );
                    })}
                </Group>
              </div>
            </ListItem>
          );
        })}
      </Group>
    </Root>
  );
};

export default React.memo(Tree);
