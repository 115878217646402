import * as Types from "../../../types/__generated__/graphql";

import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FetchNearestRegionQueryVariables = Types.Exact<{
  practiceId: Types.Scalars["ID"]["input"];
  latitude?: Types.InputMaybe<Types.Scalars["Float"]["input"]>;
  longitude?: Types.InputMaybe<Types.Scalars["Float"]["input"]>;
}>;

export type FetchNearestRegionQuery = {
  __typename?: "Query";
  practice?: {
    __typename?: "Practice";
    id: string;
    nearestRegion?: {__typename?: "SearchRegion"; id: string; slug?: string | null} | null;
  } | null;
};

export const FetchNearestRegionDocument = gql`
  query FetchNearestRegion($practiceId: ID!, $latitude: Float, $longitude: Float) {
    practice(id: $practiceId) {
      id
      nearestRegion(latitude: $latitude, longitude: $longitude) {
        id
        slug
      }
    }
  }
`;

/**
 * __useFetchNearestRegionQuery__
 *
 * To run a query within a React component, call `useFetchNearestRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchNearestRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchNearestRegionQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useFetchNearestRegionQuery(
  baseOptions: Apollo.QueryHookOptions<FetchNearestRegionQuery, FetchNearestRegionQueryVariables>,
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<FetchNearestRegionQuery, FetchNearestRegionQueryVariables>(
    FetchNearestRegionDocument,
    options,
  );
}
export function useFetchNearestRegionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchNearestRegionQuery,
    FetchNearestRegionQueryVariables
  >,
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<FetchNearestRegionQuery, FetchNearestRegionQueryVariables>(
    FetchNearestRegionDocument,
    options,
  );
}
export type FetchNearestRegionQueryHookResult = ReturnType<typeof useFetchNearestRegionQuery>;
export type FetchNearestRegionLazyQueryHookResult = ReturnType<
  typeof useFetchNearestRegionLazyQuery
>;
export type FetchNearestRegionQueryResult = Apollo.QueryResult<
  FetchNearestRegionQuery,
  FetchNearestRegionQueryVariables
>;
