import {colors} from "@c10h/colors";
import Icon from "@components/Icon";
import {FilterType} from "@components/LocationMapForLocations";
import RadioDropdown from "@components/RadioDropdown";
import RegionTreeSelector from "@components/Region/ReduxRegionTreeSelector";
import {useTranslation} from "ni18n";
import React, {memo, useCallback, useMemo} from "react";
import {useTwMediaQuery} from "src/hooks/useTwMediaQuery";
import {Breakpoint} from "src/constants/breakpoint";
import {useTypedSelector} from "src/store";

import {SpecialtyId} from "../../../constants/specialtyIds";
import {TreeItem} from "@components/components/Tree/ComplexTreeWrapper";
import {useRouter} from "next/router";
import {RegionSlug} from "../../../store/types";
import {v5Pages} from "../../_common/_constants";

export const getMapOptionStyle = (fill = false) =>
  `px-2 py-1 rounded-[4px] gap-2 border border-solid border-gray-800/10 font-i text-base leading-loose cursor-pointer flex items-center h-[50px] ${
    fill ? "bg-gray-800/10" : "bg-transparent"
  }`;

type Props = {
  // @ts-expect-error TS7008: Member 'getToggleFilterHandler' implicitly has an 'any' type.
  getToggleFilterHandler;
  // @ts-expect-error TS7008: Member 'filters' implicitly has an 'any' type.
  filters;
  // @ts-expect-error TS7008: Member 'isFilterActive' implicitly has an 'any' type.
  isFilterActive;
  // @ts-expect-error TS7008: Member 'locate' implicitly has an 'any' type.
  locate;

  onRegionSelect?: (slug: RegionSlug) => unknown;

  regionSlug: RegionSlug | undefined;
};

const MapOptions: React.FC<Props> = ({
  getToggleFilterHandler,
  filters,
  isFilterActive,
  locate,
  onRegionSelect,
  regionSlug,
}) => {
  const i18n = useTranslation();
  const {query} = useRouter();

  const isSm = !useTwMediaQuery(Breakpoint.MD);

  const {locations} = useTypedSelector(state => state.config);

  const filterButtonData = useMemo(
    () =>
      locations
        .flatMap(l => l.specialties.vals())
        .distinctBy("id")
        .filter(s => s.id !== SpecialtyId.ORTHOPEDICS && s.isVirtual === false)
        .sortBy("sortIndex")
        .map(s => ({
          func: filters.specialtyId(s.id),
          name: s.name,
          sId: s.id,
          typ: FilterType.SPECIALTY,
        })),
    [filters, locations],
  );

  const options = useMemo(
    () =>
      filterButtonData.map(f => ({
        text: f.name,
        value: f,
        selected: isFilterActive(f),
      })),
    [filterButtonData, isFilterActive],
  );

  const onSelect = useCallback(
    // @ts-expect-error TS7006: Parameter 'value' implicitly has an 'any' type.
    value => {
      getToggleFilterHandler(value)();
    },
    [getToggleFilterHandler],
  );

  const isXs = !useTwMediaQuery(Breakpoint.SM);

  const getHref = useCallback(
    (item: TreeItem<string>) =>
      item.children
        ? undefined
        : {
            query: {...query, slug: item.id},
          },
    [query],
  );
  const getAs = useCallback(
    (item: TreeItem<string>) => (item.children ? undefined : `${v5Pages.locations}/${item.id}`),
    [],
  );

  return (
    <div
      className="px-4 py-3 contrast-tbb bg-white"
      style={{boxShadow: `0px 1px 1px 0px ${colors.gray800 + 10}`}}
    >
      <div className="flex flex-wrap justify-end gap-1 w-full">
        <div className="flex flex-nowrap gap-1 items-center grow">
          <RegionTreeSelector
            key={regionSlug} // force re-render on region change
            buttonVariant="locations"
            hideVirtualOnly
            onSelect={onRegionSelect}
            getHref={getHref}
            getAs={getAs}
            initialRegionSlug={regionSlug}
            shallow
          />
          <button className={`${getMapOptionStyle(false)} hidden md:block`} onClick={locate}>
            <div className="flex items-center gap-2">
              <Icon icon="locArrow" className="jcc items-center fs14 text-gray-700 px-2 sm:px-0" />
              <span className="hidden sm:flex">{i18n.t("Locate Me")}</span>
            </div>
          </button>
        </div>
        <RadioDropdown
          text={i18n.t("Services")}
          options={options}
          onSelect={onSelect}
          anchor={!isSm && isXs ? "bottom center" : "bottom left"}
        />
      </div>
    </div>
  );
};

export default memo(MapOptions);
