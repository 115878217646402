import {ButtonVariant} from "@components/components/Tree/types";
import React, {useMemo} from "react";
import {useTypedSelector} from "../../../store";
import {RegionSlug} from "../../../store/types";
import {generateRegionData} from "../../../utils/regions/regionUtils";
import {TreeItem} from "../components/Tree/ComplexTreeWrapper";
import {Url} from "next/dist/shared/lib/router/router";
import {LinkProps} from "next/link";
import {useUserSelectedLocation} from "src/utils/browser-storage/userSelectedLocation";
import RegionTreeSelector from "@components/Region/RegionTreeSelector";

interface ReduxRegionTreeSelector {
  buttonVariant?: ButtonVariant;
  initialRegionSlug?: string;
  onSelect?: (slug: RegionSlug) => void;
  hideVirtualOnly?: boolean;
  inClinicSpecialtyIds?: string[];
  getHref?: (item: TreeItem<string>) => Url | undefined;
  getAs?: (item: TreeItem<string>) => LinkProps["as"] | undefined;
  shallow?: LinkProps["shallow"];
}

const ReduxRegionTreeSelector: React.FC<ReduxRegionTreeSelector> = ({
  buttonVariant = "primary",
  onSelect,
  initialRegionSlug,
  inClinicSpecialtyIds,
  hideVirtualOnly = false,
  getHref,
  getAs,
  shallow,
}) => {
  const {regionSlug: selectedFromLocalStorage = "sf-bay-area"} = useUserSelectedLocation();
  const {locations} = useTypedSelector(({config}) => config);

  const selectedRegion = initialRegionSlug ?? selectedFromLocalStorage;

  const regions = useMemo(
    () => generateRegionData(locations, inClinicSpecialtyIds, hideVirtualOnly),
    [hideVirtualOnly, inClinicSpecialtyIds, locations],
  );

  return (
    <RegionTreeSelector
      buttonVariant={buttonVariant}
      getAs={getAs}
      getHref={getHref}
      onSelect={onSelect}
      regions={regions}
      selectedRegionSlug={selectedRegion}
      shallow={shallow}
    />
  );
};

export default React.memo(ReduxRegionTreeSelector);
